<template>
	<div>
		<applicationBulkUploadDialog
			v-if="applicationBulkUploadDialog.dialogModel"
			v-bind="applicationBulkUploadDialog"
			@closeDialog="applicationBulkUploadDialogClose()"
			@reloadApplications="reloadApplications"
		></applicationBulkUploadDialog>
		<v-speed-dial
			fixed
			transition="slide-y-reverse-transition"
			class="mb-6"
			v-model="speedDial"
			direction="top"
			bottom
			right
		>
			<template v-slot:activator>
				<v-btn v-model="speedDial" color="blue darken-2 white--text" fab>
					<v-icon v-if="speedDial">mdi-close</v-icon>
					<v-icon v-else>mdi-format-list-bulleted-type</v-icon>
				</v-btn>
			</template>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn
						fab
						class="white--text"
						small
						color="indigo"
						@click="applicationBulkUploadDialogShow()"
						v-on="on"
					>
						<v-icon>mdi-upload</v-icon>
					</v-btn>
				</template>
				<span>Upload File</span>
			</v-tooltip>
		</v-speed-dial>
	</div>
</template>

<script>
	import applicationBulkUploadDialog from "@/components/dialogs/applicationBulkUploadDialog";
	export default {
		components: { applicationBulkUploadDialog },
		data: () => ({
			speedDial: false,
			applicationBulkUploadDialog: {
				formTypeProp: "flood",
				dialogModel: false,
				selectedModel: null
			}
		}),
		watch: {},
		mounted() {},
		methods: {
			applicationBulkUploadDialogShow() {
				this.applicationBulkUploadDialog.dialogModel = true;
			},
			applicationBulkUploadDialogClose() {
				this.applicationBulkUploadDialog.dialogModel = false;
			},
			reloadApplications() {
				this.$emit("reloadApplications");
			}
		}
	};
</script>
